import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-date-picker";

import compareAsc from "date-fns/compareAsc";
import addWeeks from "date-fns/addWeeks";
import { motion } from "framer-motion";
import Select from "react-select";
import { months } from "../utils/options";

import {
  getServicesAction,
  toggleCompleteServicesAction,
  getMonthAction,
  getMonthRangeAction,
  getCustomDateRangeAction,
  todayAction,
  weekAction,
  deleteServiceAction,
} from "../actions/creators/services/service_creators";
import ServicesList from "../components/services/services-list";
import ModalPage from "../components/modals/modal_page";
import EditService from "../components/customers/edit-services";
import { Warning, Error } from "../components/toasts/toasts";
import { hoverVariant, btnFilter } from "../variants/bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import PageMotion from "../page-motion";
import Loader from "../components/shared/Loader";
import MobileTools from "../components/services/mobile-tools";

const Services = () => {
  //use dispatch
  const dispatch = useDispatch();

  toast.configure();

  const { services, loading } = useSelector((state) => state.services);

  const [open, setOpen] = useState([]);
  const [done, setDone] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [updateStatusService, setStatusServices] = useState(false);
  const [filters, setStatusFilters] = useState(false);
  const [service, setService] = useState("");
  const [month, setMonth] = useState(null);
  const [start, setStartMonth] = useState(null);
  const [end, setEndMonth] = useState(null);
  const [customStart, setCustomStartMonth] = useState(new Date());
  const [customEnd, setCustomEndMonth] = useState(addWeeks(new Date(), 4));
  const fullService = true;
  //bar btns
  const [today, setToday] = useState(false);
  const [week, setWeek] = useState(false);

  //load services
  useEffect(() => {
    dispatch(getServicesAction());
  }, [dispatch]);

  useEffect(() => {
    const opn = services.filter((s) => s.complete === 0);
    const dn = services.filter((s) => s.complete === 1);
    setOpen(opn);
    setDone(dn);
  }, [services]);

  const onChangeMonth = (d) => {
    setMonth(d);
    setStartMonth(null);
    setEndMonth(null);
    setStatusFilters(false);
    // dispatch(getMonthAction(d));
  };
  useEffect(() => {
    if (!month) {
      return;
    }
    dispatch(getMonthAction(month));
  }, [month, dispatch]);

  const onFilter = () => {
    if (start === "" || end === "") {
      return;
    }
    const payload = {
      start: start.value,
      end: end.value,
    };
    dispatch(getMonthRangeAction(payload));
    setStatusFilters(false);
  };
  //make toast
  const onCustom = () => {
    if (customStart === null || customEnd === null) {
      alert("Cant be empty");
      return;
    }
    const compare = compareAsc(customStart, customEnd);

    if (compare === 0) {
      alert("Can not be the same day");
      return;
    }
    const payload = {
      start: customStart,
      end: customEnd,
    };

    dispatch(getCustomDateRangeAction(payload));
    setStatusFilters(false);
  };

  const updateComplete = (id, complete) => {
    let status = complete === 0 ? 1 : 0;

    if (complete === 1) {
      toast.warn(
        <Warning
          btn="Reverse"
          icn={faCheck}
          colr="#fff"
          onAction={() =>
            dispatch(toggleCompleteServicesAction({ id, status }))
          }
        >
          <h3>Warning</h3>
          <p>
            This action will change your completed service back to pending.
            Futher, any auto generated bookings or appointments connected to
            this service will be destroyed.
          </p>
          <p>To be proceed, click the REVERSE button below.</p>
        </Warning>
      );
      return;
    }

    dispatch(toggleCompleteServicesAction({ id, status }));
  };

  const deleteService = (status) => {
    if (status.complete === 1) {
      toast.error("Completed services can not be destroyed.", {
        autoClose: 8000,
      });
      return;
    }
    const { id, type, customer_id } = status;
    const payload = {
      id,
      type,
      customer_id,
    };
    toast.error(
      <Error destroy={() => dispatch(deleteServiceAction(payload))} />,
      {
        position: toast.POSITION.CENTER,
        autoClose: false,
      }
    );
  };

  const editService = (services) => {
    setStatusServices(true);
    setService(services);
  };
  const onToday = () => {
    setToday(true);
    setWeek(false);
    dispatch(todayAction());
  };
  const onWeek = () => {
    setToday(false);
    setWeek(true);
    dispatch(weekAction());
  };

  const onReset = () => {
    dispatch(getServicesAction());

    toast.info("All Filters Reset.", {
      position: toast.POSITION.RIGHT_CENTER,
      autoClose: 8000,
    });
    setStatusServices(false);
  };

  const toggleFilters = () => {
    setStatusFilters(!filters);
  };

  return (
    <PageMotion>
      {updateStatusService && (
        <ModalPage closeModal={() => setStatusServices(false)}>
          <EditService
            service={service}
            setStatusServices={setStatusServices}
            fullService={fullService}
          />
        </ModalPage>
      )}
      {filters && (
        <ModalPage closeModal={() => setStatusFilters(false)}>
          <h6>Change Month</h6>
          {/* <DatePicker className="date-pick" onChange={setMonth} value={month} /> */}
          <div className="date-pick">
            <Select
              options={months}
              placeholder="Select Month"
              onChange={onChangeMonth}
            />
          </div>

          <h6>Month Range</h6>
          <div className="date-pick">
            <Select
              options={months}
              placeholder="Start Month"
              onChange={setStartMonth}
            />
          </div>
          <div className="date-pick">
            <Select
              options={months}
              placeholder="End Month"
              onChange={setEndMonth}
            />
          </div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            whileTap="tap"
            className="btn-filter"
            onClick={() => onFilter()}
          >
            Apply Range
          </motion.div>
          <h6>Custom Date Range</h6>
          <div className="date-pick">
            <DatePicker onChange={setCustomStartMonth} value={customStart} />
          </div>
          <div className="date-pick">
            <DatePicker onChange={setCustomEndMonth} value={customEnd} />
          </div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            whileTap="tap"
            className="btn-filter"
            onClick={() => onCustom()}
          >
            Apply Dates
          </motion.div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            whileTap="tap"
            className="btn-filter"
            onClick={() => onReset()}
          >
            Reset Filters
          </motion.div>
        </ModalPage>
      )}

      <div className="services">
        {/* Mobile below 768px*/}

        <MobileTools
          open={open}
          done={done}
          hoverVariant={hoverVariant}
          setToggle={setToggle}
          today={today}
          week={week}
          toggle={toggle}
          onToday={onToday}
          onWeek={onWeek}
          onReset={onReset}
          toggleFilters={toggleFilters}
        />

        {/* Desktop and tablet*/}
        <div className="side">
          <h2>Services</h2>
          <div className="due">
            <h5>Pending</h5>
            <div className="pending">{open.length > 0 ? open.length : 0}</div>
          </div>
          <div className="due">
            <h5>Complete</h5>
            <div>{done.length > 0 ? done.length : 0}</div>
          </div>
          <hr />
          <motion.div
            variants={hoverVariant}
            whileHover="hover"
            className="bar-btns"
            onClick={() => setToggle((prevToggle) => !prevToggle)}
          >
            <h6>Status</h6>
            {toggle ? (
              <FontAwesomeIcon icon={faCheck} color="#7bb6cf" />
            ) : (
              <FontAwesomeIcon icon={faCalendar} color="#eb5e55" />
            )}
          </motion.div>
          {/* <h6>Current Month {getCurrentMonthWords()}</h6> */}
          <motion.div
            onClick={() => onToday()}
            variants={hoverVariant}
            whileHover="hover"
            className="bar-btns"
          >
            <h6>Today</h6>
            {today && <div className="mini-dot"></div>}
          </motion.div>
          <motion.div
            onClick={() => onWeek()}
            variants={hoverVariant}
            whileHover="hover"
            className="bar-btns"
          >
            <h6>Current Week</h6>
            {week && <div className="mini-dot"></div>}
          </motion.div>

          <hr />
          <h6>Change Month</h6>
          {/* <DatePicker className="date-pick" onChange={setMonth} value={month} /> */}
          <div className="date-pick">
            <Select
              options={months}
              placeholder="Select Month"
              onChange={onChangeMonth}
            />
          </div>

          <h6>Month Range</h6>
          <div className="date-pick">
            <Select
              options={months}
              placeholder="Start Month"
              onChange={setStartMonth}
            />
          </div>
          <div className="date-pick">
            <Select
              options={months}
              placeholder="End Month"
              onChange={setEndMonth}
            />
          </div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            whileTap="tap"
            className="btn-filter"
            onClick={() => onFilter()}
          >
            Apply Range
          </motion.div>
          <h6>Custom Date Range</h6>
          <div className="date-pick">
            <DatePicker onChange={setCustomStartMonth} value={customStart} />
          </div>
          <div className="date-pick">
            <DatePicker onChange={setCustomEndMonth} value={customEnd} />
          </div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            whileTap="tap"
            className="btn-filter"
            onClick={() => onCustom()}
          >
            Apply Dates
          </motion.div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            whileTap="tap"
            className="btn-filter"
            onClick={() => onReset()}
          >
            Reset Filters
          </motion.div>
        </div>
        <div className="service-main">
          {loading ? (
            <Loader />
          ) : toggle ? (
            <>
              {open.length > 0
                ? open.map((serv) => (
                    <ServicesList
                      key={serv.id}
                      services={serv}
                      updateComplete={updateComplete}
                      deleteService={deleteService}
                      editService={editService}
                      fullService={fullService}
                    />
                  ))
                : "No services"}
            </>
          ) : (
            <>
              {done.length > 0
                ? done.map((serv) => (
                    <ServicesList
                      key={serv.id}
                      services={serv}
                      updateComplete={updateComplete}
                      deleteService={deleteService}
                      editService={editService}
                      fullService={fullService}
                    />
                  ))
                : "No services"}
            </>
          )}
        </div>
      </div>
    </PageMotion>
  );
};

export default Services;
