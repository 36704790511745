import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomers } from "../actions/shared";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { btnFilter } from "../variants/bar";
import CustomerList from "../components/customers/customer-list";
import ModalPage from "../components/modals/modal_page";
import NewCustomer from "../components/customers/new-customer";
import Bar from "../components/shared/Bar";
import Loader from "../components/shared/Loader";
import PageMotion from "../page-motion";
import ListMotion from "../list-motion";
import { toast } from "react-toastify";
import { showAllAction, todayAction, weekAction } from "../actions/customers";

const Customers = () => {
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const {
    customers,
    customer,
    loading,
    currentPage,
    lastPage,
    total,

    page,
  } = useSelector((state) => state.customerReducer);

  const [newCustomer, setNewCustomer] = useState(false);
  // const [current, setCurrent] = useState(currentPage);

  const [track, setTrack] = useState(1);

  const [pagination, setPagination] = useState(true);

  useEffect(() => {
    dispatch(
      getAllCustomers({
        page,
        status: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllCustomers({
        page,
        status: track,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (customer.hasOwnProperty("id")) {
      toast.success(`New customer added. Welcome ${customer.name}!`, {
        position: toast.POSITION.CENTER,
        autoClose: 7000,
      });
    }
  }, [customer]);

  const previous = () => {
    if (track <= 1) {
      return;
    }
    setTrack((prevCount) => prevCount - 1);
  };

  const next = () => {
    if (track >= lastPage) {
      return;
    }
    setTrack((prevCount) => prevCount + 1);
  };

  const showAll = () => {
    dispatch(showAllAction());
    setPagination(false);
  };

  const today = () => {
    dispatch(todayAction());
    setPagination(false);
  };

  const week = () => {
    dispatch(weekAction());
    setPagination(false);
  };
  const month = () => {};

  const year = () => {};

  const reset = () => {
    dispatch(
      getAllCustomers({
        page,
        status: 1,
      })
    );
    setPagination(true);
  };

  return (
    <PageMotion>
      {newCustomer && (
        <ModalPage closeModal={() => setNewCustomer(false)}>
          <NewCustomer
            setNewCustomer={setNewCustomer}
            setPagination={setPagination}
            currentPage={currentPage}
            page={page}
          />
        </ModalPage>
      )}

      <>
        <div className="sub_header">
          <div className="page-header">
            <h2>Customers</h2>
          </div>

          <motion.div
            whileHover={{
              scale: 0.9,
              color: "#f8e112",
            }}
            whileTap={{
              scale: 0.95,
            }}
            className="circle-icon"
            onClick={() => setNewCustomer(true)}
          >
            <FontAwesomeIcon icon={faUserPlus} size="1x" />
          </motion.div>
        </div>
        <div className="container">
          <Bar
            total={total}
            currentPage={currentPage}
            customers={customers}
            page={page}
            showAll={showAll}
            today={today}
            week={week}
            month={month}
            year={year}
            reset={reset}
            pagination={pagination}
          />

          {loading ? (
            <div className="customers">
              <Loader />
            </div>
          ) : (
            <ListMotion>
              <div className="customers">
                {customers.length > 0
                  ? customers.map((cust) => (
                      <CustomerList customers={cust} key={cust.id} />
                    ))
                  : "No results found."}

                {pagination && (
                  <div className="pagination">
                    <motion.div
                      variants={btnFilter}
                      whileHover="hover"
                      onClick={() => previous()}
                    >
                      Previous
                    </motion.div>
                    <motion.div
                      variants={btnFilter}
                      whileHover="hover"
                      onClick={() => next()}
                    >
                      Next
                    </motion.div>
                  </div>
                )}
              </div>
            </ListMotion>
          )}
        </div>
      </>
    </PageMotion>
  );
};

export default Customers;
