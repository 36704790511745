import {
  LOADING,
  ERRORS,
  STATS,
  JOBS,
  ONE_JOB,
  JOBS_COMPLETE,
  MENU,
} from "../types/types";

const initialState = {
  stats: {},
  jobs: [],
  job: {},
  errors: null,
  loading: false,
  menu: false,
};

export const overview = (state = initialState, action) => {
  switch (action.type) {
    case STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case JOBS:
      const pending = action.payload.filter((jb) => jb.complete !== 1);

      return {
        ...state,
        jobs: pending,
      };
    case ONE_JOB:
      const selection = state.jobs.find((j) => j.id === action.id);
      return {
        ...state,
        job: selection ? selection : {},
      };
    case JOBS_COMPLETE:
      const completedJobs = state.jobs.filter((j) => j.id !== action.id);

      // const toggle = state..map((item) => {
      //   if (item.id !== id) {
      //     return item;
      //   }
      //   return Object.assign({}, item, { complete: status });
      // });

      return {
        ...state,
        jobs: completedJobs,
        job: {},
      };
    case LOADING:
      return {
        ...state,
        loading: action.load,
      };
    case ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case MENU:
      return {
        ...state,
        menu: (state.menu = !state.menu),
      };

    default:
      return state;
  }
};
