import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Auth from "./views/Auth";
import Customers from "./views/Customers";
import Services from "./views/Services";
import Account from "./views/Account";
import Calendars from "./views/Calendar";
import Overview from "./views/Overview";
import PageNotFound from "./views/404.js";
import Customer from "./views/Customer";
import Invoices from "./views/Invoices";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Guest from "./layout/Guest";
import AuthLayout from "./layout/Auth";

import { Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

function App() {
  return (
    <>
      <Router>
        <AnimatePresence exitBeforeEnter>
          <Switch>
            <RouteWrapper path="/" exact component={Auth} layout={Guest} />

            <RouteWrapper
              path="/overview"
              exact
              component={Overview}
              layout={AuthLayout}
            />
            <RouteWrapper
              path="/customers"
              exact
              component={Customers}
              layout={AuthLayout}
            />
            <RouteWrapper
              path="/customers/:id"
              exact
              component={Customer}
              layout={AuthLayout}
            />
            <RouteWrapper
              path="/services"
              exact
              component={Services}
              layout={AuthLayout}
            />
            <RouteWrapper
              path="/invoices"
              exact
              component={Invoices}
              layout={AuthLayout}
            />
            <RouteWrapper
              path="/account"
              exact
              component={Account}
              layout={AuthLayout}
            />
            <RouteWrapper
              path="/calendar"
              exact
              component={Calendars}
              layout={AuthLayout}
            />

            <RouteWrapper path="*" component={PageNotFound} layout={Guest} />
          </Switch>
        </AnimatePresence>
        <ToastContainer />
      </Router>
    </>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      // render={(props) => (
      //   <Layout {...props}>
      //     {/* {!isAuth ? <Redirect to="/" /> : <Component {...props} />} */}
      //     <Component {...props} />
      //   </Layout>
      // )}

      render={(props) =>
        !isAuth ? (
          <Layout {...props}>
            <Redirect to="/" />
            <Component {...props} />
          </Layout>
        ) : (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
}

export default App;
