import gql from "graphql-tag";

export const Week = gql`
  query {
    week {
      id
      type
      date
      price
      description
      updated_at
      complete
      arrive
      customer_id
      customers {
        id
        name
        phone
      }
    }
  }
`;

export const Today = gql`
  query {
    today {
      id
      type
      date
      price
      description
      arrive
      updated_at
      complete
      customer_id
      customers {
        id
        name
        phone
        street
        zip
        city
        state
      }
    }
  }
`;

export const CustomDateRange = gql`
  query($start: String!, $end: String!) {
    customDateRange(start: $start, end: $end) {
      id
      type
      date
      description
      updated_at
      complete
      price
      customer_id
      customers {
        id
        name
        phone
      }
    }
  }
`;

export const MonthRange = gql`
  query($start: String!, $end: String!) {
    monthRange(start: $start, end: $end) {
      id
      type
      date
      description
      updated_at
      arrive
      complete
      price
      customer_id
      customers {
        id
        name
        phone
      }
    }
  }
`;

export const ChangeMonth = gql`
  query($month: String!) {
    changeMonth(month: $month) {
      id
      type
      date
      price
      description
      updated_at
      complete
      arrive
      customer_id
      customers {
        id
        name
        phone
      }
    }
  }
`;

export const ServiceDelete = gql`
  mutation($id: ID!) {
    serviceDelete(id: $id) {
      id
    }
  }
`;

export const DeleteService = gql`
  mutation($id: ID!, $customer_id: Int!, $type: String!) {
    delete: deleteService(id: $id, customer_id: $customer_id, type: $type)
  }
`;

export const NewService = gql`
  mutation(
    $description: String!
    $type: String!
    $date: Date!
    $arrive: DateTime!
    $due: Date
    $price: Float!
    $customer_id: ID!
  ) {
    newServices(
      input: {
        description: $description
        type: $type
        date: $date
        arrive: $arrive
        due: $due
        price: $price
        customer_id: $customer_id
      }
    ) {
      id
      date
      due
      description
      type
      arrive
      price
      customer_id
      updated_at
      complete
      customers {
        id
        name
      }
    }
  }
`;

export const UpdateService = gql`
  mutation(
    $id: ID!
    $date: Date
    $due: Date
    $arrive: DateTime
    $description: String
    $type: String
    $price: Float
    $complete: Int
  ) {
    updateService(
      input: {
        id: $id
        date: $date
        due: $due
        arrive: $arrive
        description: $description
        type: $type
        price: $price
        complete: $complete
      }
    ) {
      id
      date
      due
      description
      type
      arrive
      complete
      price
      updated_at
      customer_id
      customers {
        id
        name
      }
    }
  }
`;

export const CheckSchedule = gql`
  query($id: ID!, $date: Date!) {
    check: checkSchedule(id: $id, date: $date)
  }
`;

export const Services = gql`
  query {
    services {
      id
      type
      description
      date
      arrive
      complete
      price
      updated_at
      created_at
      customer_id
      customers {
        id
        name
        phone
      }
    }
  }
`;

// export const Services = gql`
//   query($complete: Int!, $first: Int!, $page: Int!, $from: Date!, $to: Date!) {
//     services(
//       complete: $complete
//       first: $first
//       page: $page
//       due: { from: $from, to: $to }
//     ) {
//       data {
//         id
//         type
//         date
//         due
//         complete
//         updated_at

//         customers {
//           id
//           name
//         }
//       }
//       paginatorInfo {
//         count
//         currentPage
//         lastItem
//         lastPage
//         total
//       }
//     }
//   }
// `;

export const Service = gql`
  query($id: ID!) {
    service(id: $id) {
      id
      date
      type
      due
      price
      created_at
      updated_at
      description
      customer_id
      arrive
    }
  }
`;
