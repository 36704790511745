import { client } from "../apollo";
import { Overview } from "../graphql/overview";
import { Today, UpdateService } from "../graphql/services";
import {
  LOADING,
  STATS,
  JOBS_COMPLETE,
  JOBS,
  ONE_JOB,
  MENU,
} from "../types/types";

export const statsAction = () => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: Overview,
      fetchPolicy: "network-only",
    });

    dispatch(stats(data.overview));
  } catch (error) {
    console.log(error);
  }
};

export const stats = (payload) => {
  return {
    type: STATS,
    payload,
  };
};

export const jobCompleteServicesAction = (payload) => async (dispatch) => {
  dispatch(jobsComplete(payload.id));
  try {
    await client.mutate({
      mutation: UpdateService,
      variables: {
        id: payload.id,
        complete: payload.status,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const jobsComplete = (id) => {
  return {
    type: JOBS_COMPLETE,
    id,
  };
};

export const todayAction = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const { data } = await client.query({
      query: Today,
      fetchPolicy: "network-only",
    });

    dispatch(jobs(data.today));

    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
    console.log(error);
  }
};
export const jobs = (payload) => {
  return {
    type: JOBS,
    payload,
  };
};

export const oneJob = (id) => {
  return {
    type: ONE_JOB,
    id,
  };
};
export const loading = (load) => {
  return {
    type: LOADING,
    load,
  };
};

//menu

export const menuAction = () => {
  return {
    type: MENU,
  };
};
