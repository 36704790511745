import { useSelector } from "react-redux";
import { useState } from "react";
import { motion } from "framer-motion";

import PageMotion from "../page-motion";

import { faCog, faShield } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Security from "./Security";
import Settings from "./Settings";
import User from "../components/Account/User";
import { hoverVariant } from "../variants/bar";

const Account = () => {
  const { user } = useSelector((state) => state.auth);
  const { page } = useSelector((state) => state.customerReducer);

  const [security, setSecurity] = useState(false);
  const [settings, setSettings] = useState(true);

  const onSecurity = () => {
    setSettings(false);
    setSecurity(true);
  };

  const onSettings = () => {
    setSecurity(false);
    setSettings(true);
  };

  return (
    <PageMotion>
      <div className="grid-2">
        <User user={user} />
        <div className="controls">
          <div className="sub-header">
            <ul>
              <motion.li
                variants={hoverVariant}
                whileHover="hover"
                onClick={() => onSettings()}
              >
                <FontAwesomeIcon icon={faCog} />
                <span>Settings</span>
              </motion.li>
              <motion.li
                variants={hoverVariant}
                whileHover="hover"
                onClick={() => onSecurity()}
              >
                <FontAwesomeIcon icon={faShield} />
                <span>Security</span>
              </motion.li>
            </ul>
          </div>

          {settings && <Settings page={page} />}
          {security && <Security />}
        </div>
      </div>
    </PageMotion>
  );
};

export default Account;
