//auth actions

import { client } from "../apollo";
import { Login, Logout, UpdatePassword, UpdateUser } from "../graphql/auth";
import {
  IS_AUTH,
  LOGOUT,
  UPDATE_PASSWORD,
  UPDATE_USER,
  ERRORS,
} from "../types/types";

//action creator

//action creator
export const loginCreator = (payload) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: Login,
      variables: payload,
    });
    if (!data.login.token) {
      dispatch(error(data.login.original.message));
      return;
    }
    dispatch(login(data));
    sessionStorage.setItem("token", data.login.token);
    sessionStorage.setItem("isAuth", true);
    const { email, created_at, name } = data.login;
    const user = {
      name,
      email,
      created_at,
    };
    sessionStorage.setItem("user", JSON.stringify(user));
  } catch (error) {}
};

export const error = (error) => {
  return {
    type: ERRORS,
    error,
  };
};

//action
export const login = (payload) => {
  return {
    type: IS_AUTH,
    payload,
  };
};

//action creator logout
export const logoutCreator = () => async (dispatch) => {
  dispatch(logout());
  try {
    await client.mutate({
      mutation: Logout,
    });

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isAuth");
    sessionStorage.removeItem("user");
  } catch (error) {}
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const updatePasswordCreator = (payload) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: UpdatePassword,
      variables: payload,
    });
  } catch (error) {}
};
export const updatePassword = () => {
  return {
    type: UPDATE_PASSWORD,
  };
};

export const updateUserAction = (payload) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: UpdateUser,
      variables: payload,
    });
    const { email, created_at, name } = data.updateUser;
    const user = {
      name,
      email,
      created_at,
    };
    sessionStorage.setItem("user", JSON.stringify(user));
    dispatch(updateUser(user));
  } catch (error) {}
};

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    user,
  };
};
