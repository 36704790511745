import { useState } from "react";
import { useDispatch } from "react-redux";
import { newServiceAction } from "../../actions/shared";
import { motion } from "framer-motion";
import { faWrench } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import { formatDateTimeSQL, formatDateForSQL } from "../../utils/dates";
import Select from "react-select";
import { servicesOptions } from "../../utils/options";
import { ServiceSchema } from "../../schema/validations";
import { Formik, Form, Field } from "formik";

const NewService = ({ id, setNewService }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState("");
  const [value, onChange] = useState(new Date());

  const newServices = (values) => {
    if (type.value === undefined) {
      toast.error("Service type must be selected to move forward. ", {
        position: toast.POSITION.CENTER,
        autoClose: 5000,
      });
      return;
    }
    if (!value) {
      toast.error(
        "You have entered an invalid date. Please check date and of service.",
        {
          position: toast.POSITION.CENTER,
          autoClose: 5000,
        }
      );
      return;
    }
    const payload = {
      type: type.value,
      price: parseFloat(values.price),
      arrive: formatDateTimeSQL(value),
      date: formatDateForSQL(value),
      description: values.description,
      customer_id: id,
    };

    dispatch(newServiceAction(payload));

    setNewService(false);
    console.log(payload);
  };

  return (
    <div className="form-page">
      <Formik
        initialValues={{
          description: "",
          price: "",
        }}
        validationSchema={ServiceSchema}
        onSubmit={(values) => {
          newServices(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="input-control">
              <h4>
                Current Service:{" "}
                {type.value ? type.value : " Select a Service."}
              </h4>
              <Select
                placeholder="Select Service Type"
                options={servicesOptions}
                onChange={setType}
              />
            </div>
            <div className="input-date">
              <h4>Job date & arrival time.</h4>
              <DateTimePicker onChange={onChange} value={value} />
            </div>
            <div className="input-control">
              <Field
                as="input"
                placeholder="Enter Estimate, Ex. 1234.00 no dollar sign."
                name="price"
              ></Field>
            </div>
            <div className="input-control">
              <Field
                as="textarea"
                placeholder="Enter Service Notes"
                name="description"
                cols="30"
                rows="10"
              ></Field>
              {errors.notes && touched.notes ? (
                <div className="form-errors">{errors.notes}</div>
              ) : null}
            </div>

            <motion.button
              type="submit"
              whileHover={{
                scale: 0.9,
                color: "#f8e112",
              }}
              transition={{
                type: "spring",
                stiffness: 300,
              }}
              className="form-btn cursor"
            >
              <span>New Service</span>
              <FontAwesomeIcon icon={faWrench} color="#FFF" size="1x" />
            </motion.button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewService;
