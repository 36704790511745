import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageMotion from "../page-motion";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import toDate from "date-fns/toDate";
import { getServicesAction } from "../actions/creators/services/service_creators";
import Loader from "../components/shared/Loader";
import addHours from "date-fns/addHours";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Calendars = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServicesAction());
    // return () => {
    //   cleanup;
    // };
  }, [dispatch]);

  const { services, loading } = useSelector((state) => state.services);

  const jobs =
    services.length > 0
      ? services.map((s) => {
          const serv = {
            start: s.arrive
              ? toDate(new Date(s.arrive))
              : toDate(new Date(s.date)),
            end: s.arrive
              ? toDate(addHours(new Date(s.arrive), 1))
              : toDate(addHours(new Date(s.date), 1)),
            title: s.type,
          };
          return serv;
        })
      : services;

  const [events, setEvents] = useState([
    {
      start: toDate(new Date()),
      end: toDate(new Date()),
      title: "No Jobs",
    },
  ]);

  useEffect(() => {
    setEvents(jobs);
    return () => {
      setEvents([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  return (
    <PageMotion>
      <div className="page">
        {loading ? (
          <Loader />
        ) : (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
          />
        )}
      </div>
    </PageMotion>
  );
};

export default Calendars;
