export const linkNav = [
  {
    title: "Overview",
    path: "/overview",
  },
  {
    title: "Customers",
    path: "/customers",
  },
  {
    title: "Services",
    path: "/services",
  },
  {
    title: "Invoices",
    path: "/invoices",
  },
  {
    title: "Calendar",
    path: "/calendar",
  },
  {
    title: "Account",
    path: "/account",
  },
];
