import { useState } from "react";
import { motion } from "framer-motion";
import {
  faUserCircle,
  faUser,
  faEnvelope,
  faPenAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalPage from "../modals/modal_page";
import UserUpdate from "./user-update";
import { formatDateTimeNoTime } from "../../utils/dates";
import { btnFilter } from "../../variants/bar";

const User = ({ user }) => {
  const [update, setUpdate] = useState(false);
  const { name, email, created_at } = user;
  return (
    <>
      <div className="accounts">
        {update && (
          <ModalPage closeModal={() => setUpdate(false)}>
            <div className="title mb">Update User</div>
            <UserUpdate setUpdate={setUpdate} user={user} />
          </ModalPage>
        )}
      </div>
      <div className="profile">
        <div className="profile-box">
          <div className="profile-avatar">
            <FontAwesomeIcon icon={faUserCircle} size="6x" />
          </div>
          <div className="name">
            <h3>{name}</h3>
            <h5>Administrator</h5>
          </div>

          <div className="phone">
            <div>
              <FontAwesomeIcon icon={faEnvelope} size="1x" />
              <span>Email</span>
            </div>
            <div>{email}</div>
          </div>

          <div className="phone">
            <div>
              <FontAwesomeIcon icon={faUser} size="1x" />
              <span>Account started</span>
            </div>
            <div>
              {user.hasOwnProperty("created_at")
                ? formatDateTimeNoTime(created_at)
                : ""}
            </div>
          </div>
          <motion.div
            variants={btnFilter}
            whileHover="hover"
            onClick={() => setUpdate(true)}
            className="edit_btn cursor"
          >
            <span>Edit {name}</span>
            <FontAwesomeIcon icon={faPenAlt} size="1x" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default User;
