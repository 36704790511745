//service reducer
import {
  LOADING,
  SERVICES,
  UPDATE_SERVICE,
  TOGGLE_COMPLETE,
  ERRORS,
  DELETE_SERVICE,
} from "../types/types";

const initialState = {
  services: [],
  loading: false,
  errors: [],
};

export const services = (state = initialState, action) => {
  switch (action.type) {
    case SERVICES:
      return {
        ...state,
        services: action.payload,
      };

    case UPDATE_SERVICE:
      const service = state.services.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }
        return Object.assign({}, item, action.payload);
      });

      return {
        ...state,

        services: service,
      };

    case TOGGLE_COMPLETE:
      const { id, status } = action.payload;

      const toggle = state.services.map((item) => {
        if (item.id !== id) {
          return item;
        }
        return Object.assign({}, item, { complete: status });
      });

      return {
        ...state,
        services: toggle,
      };

    case DELETE_SERVICE:
      const filterServices = state.services.filter(
        (service) => service.id !== action.id
      );
      return {
        ...state,
        services: filterServices,
      };

    case LOADING:
      return {
        ...state,
        loading: action.load,
      };
    case ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
};
