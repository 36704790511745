import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const Loader = () => {
  return (
    <div className="loader-content">
      <motion.div
        animate={{
          rotate: 360,
        }}
        transition={{
          duration: 1,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
        }}
      >
        <FontAwesomeIcon icon={faSpinnerThird} size="3x" />
      </motion.div>
    </div>
  );
};

export default Loader;
