//actions services
import {
  LOADING,
  SERVICES,
  UPDATE_SERVICE,
  TOGGLE_COMPLETE,
  ERRORS,
  DELETE_SERVICE,
} from "../types/types";

export const getServices = (payload) => {
  return {
    type: SERVICES,
    payload,
  };
};

export const toggleCompleteServices = (payload) => {
  return {
    type: TOGGLE_COMPLETE,
    payload,
  };
};
export const updateServices = (payload) => {
  return {
    type: UPDATE_SERVICE,
    payload,
  };
};
export const deleteService = (id) => {
  return {
    type: DELETE_SERVICE,
    id,
  };
};
export const loading = (load) => {
  return {
    type: LOADING,
    load,
  };
};
export const errors = (errors) => {
  return {
    type: ERRORS,
    errors,
  };
};
