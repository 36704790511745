import gql from "graphql-tag";

export const Login = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const Logout = gql`
  mutation {
    logout
  }
`;

export const UpdateUser = gql`
  mutation($name: String, $email: Email, $password: String!) {
    updateUser(input: { name: $name, email: $email, password: $password })
  }
`;

export const UpdatePassword = gql`
  mutation($password: String!, $newPassword: String!) {
    updatePassword(input: { password: $password, newPassword: $newPassword })
  }
`;
