import { useSelector } from "react-redux";
import Toolbar from "../components/header/Toolbar";
import Menu from "../components/shared/Menu";

const AuthLayout = ({ children }) => {
  const { menu } = useSelector((state) => state.overview);
  return (
    <>
      <Menu menu={menu} />
      <Toolbar />
      <div>{children}</div>
    </>
  );
};

export default AuthLayout;
