import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { hoverVariant } from "../../variants/bar";
const Bar = ({
  total,
  customers,
  page,
  currentPage,
  showAll,
  today,
  week,
  month,
  year,
  pagination,
  reset,
}) => {
  //track customer pagination progress per  page
  const customerFiles = () => {
    const status = currentPage * page;

    return status >= total ? total : status;
  };

  // const citys = customers.map((c) => {
  //   const town = c.city.toLowerCase();
  //   return town;
  // });
  // console.log(citys);

  // let dups = [...new Set(citys)];
  // console.log(dups);

  return (
    <motion.div className="bar">
      <motion.div className="tracker mb">
        <FontAwesomeIcon icon={faUser} />
        <span>
          {pagination ? `${customerFiles()} / ${total}` : customers.length}
        </span>
      </motion.div>
      <hr />
      <div className="filter-btns">
        <motion.div
          variants={hoverVariant}
          whileHover="hover"
          className="btn-flat mt mb"
          onClick={() => showAll()}
        >
          Show All
        </motion.div>
        <motion.div
          variants={hoverVariant}
          whileHover="hover"
          className="btn-flat mb"
          onClick={() => today()}
        >
          Today
        </motion.div>
        <motion.div
          variants={hoverVariant}
          whileHover="hover"
          className="btn-flat mb"
          onClick={() => week()}
        >
          Created This Week
        </motion.div>
      </div>
      <hr />

      <div className="filters"></div>

      <div onClick={() => reset()} className="btn-filter mt">
        Reset Filters
      </div>
    </motion.div>
  );
};

export default Bar;
