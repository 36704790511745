import { client } from "../apollo";
import { ShowAll, TodayCustomers, WeekCustomers } from "../graphql/customers";

import {
  LOADING,
  GET_ALL_CUSTOMERS,
  PAGINATOR,
  GET_CUSTOMER,
  TOGGLE_COMPLETE,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER_SERVICE,
  UPDATE_CUSTOMER_SERVICE,
  FIND_CUSTOMERS,
  NEW_CUSTOMER,
  NEW_SERVICE,
  CLEAR_CUSTOMER,
  SHOW_ALL,
  TODAY,
  WEEK,
  MONTH,
  YEAR,
  PAGE_COUNT,
} from "../types/types";

//get all customers

export const newCustomer = (payload) => {
  return {
    type: NEW_CUSTOMER,
    payload,
  };
};
export const getAllCustomersAction = (payload) => {
  return {
    type: GET_ALL_CUSTOMERS,
    payload,
  };
};
export const loading = (load) => {
  return {
    type: LOADING,
    load,
  };
};
export const paginator = (payload) => {
  return {
    type: PAGINATOR,
    payload,
  };
};
export const getCustomerAction = (customer) => {
  return {
    type: GET_CUSTOMER,
    customer,
  };
};
export const findCustomerAction = (customer) => {
  return {
    type: FIND_CUSTOMERS,
    customer,
  };
};

export const toggleComplete = (payload) => {
  return {
    type: TOGGLE_COMPLETE,
    payload,
  };
};

export const updateCustomer = (payload) => {
  return {
    type: UPDATE_CUSTOMER,
    payload,
  };
};

export const newService = (payload) => {
  return {
    type: NEW_SERVICE,
    payload,
  };
};

export const deleteCustomerService = (id) => {
  return {
    type: DELETE_CUSTOMER_SERVICE,
    id,
  };
};

export const updateCustomerService = (payload) => {
  return {
    type: UPDATE_CUSTOMER_SERVICE,
    payload,
  };
};

export const clearCustomer = () => {
  return {
    type: CLEAR_CUSTOMER,
  };
};

//action creator showall
export const showAllAction = () => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: ShowAll,
      fetchPolicy: "network-only",
    });
    dispatch(showAll(data.showAll));
  } catch (error) {}
};

export const showAll = (payload) => {
  return {
    type: SHOW_ALL,
    payload,
  };
};

//action today
//action creator
export const todayAction = () => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: TodayCustomers,
      fetchPolicy: "network-only",
    });
    dispatch(showAll(data.todayCustomers));
  } catch (error) {}
};
export const today = (payload) => {
  return {
    type: TODAY,
    payload,
  };
};
//week
export const weekAction = () => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: WeekCustomers,
      fetchPolicy: "network-only",
    });
    dispatch(showAll(data.weekCustomers));
  } catch (error) {}
};
export const week = (payload) => {
  return {
    type: WEEK,
    payload,
  };
};
export const month = () => {
  return {
    type: MONTH,
  };
};
export const year = () => {
  return {
    type: YEAR,
  };
};

export const pageCount = (page) => {
  return {
    type: PAGE_COUNT,
    page,
  };
};
