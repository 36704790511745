import { Link } from "react-router-dom";
import {
  faCalendar,
  faMobile,
  faUserCircle,
  faWrench,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../utils/dates";

const CustomerList = ({ customers }) => {
  const { name, join, phone, services, id } = customers;

  const serv = services.length > 0 ? services.length : 0;
  //filter pending jobs

  //find next service due

  const findNextService = () => {
    const unComplete = services.filter((serv) => serv.complete === 0);

    if (unComplete.length > 0) {
      const dates = unComplete.map((serv) => {
        return Object.assign({}, serv, { date: new Date(serv.date) });
      });
      const sortByDate = dates.slice().sort((a, b) => a.date - b.date);

      const nextJob = sortByDate[0];

      return {
        job: nextJob.type,
        date: formatDate(nextJob.date),
      };
    } else {
      return {
        job: "No Services Due",
        date: "",
      };
    }
  };

  return (
    <div className="customer-panel">
      <div className="customer">
        <div className="avatar">
          <FontAwesomeIcon icon={faUserCircle} size="2x" />
        </div>
        <div className="customer-group">
          <Link to={`/customers/${id}`}>
            <div className="title">{name}</div>
          </Link>
          <div>
            <FontAwesomeIcon icon={faMobile} size="1x" />
            <span className="phone">{phone}</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faCalendar} size="1x" />
            <span className="date"> {join} </span>
          </div>
        </div>
      </div>
      <div className="subtitle">
        {/* <FontAwesomeIcon icon={faTools} size="1x" /> */}
        <div className="title">Next Service Due</div>

        <div className="date">{findNextService().job}</div>
        <div className="date">{findNextService().date}</div>
      </div>

      <div className="customer-services">
        <FontAwesomeIcon icon={faWrench} size="1x" />
        <div className="lrg-circle">
          <span className="number">{serv}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
