import * as Yup from "yup";

export const UpdatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Current password required"),
  newPassword: Yup.string()
    .required("New password required")
    .min(8, "Password must be at least eight characters."),
  confirmPassword: Yup.string()
    .required("Confirm new password required")
    .min(8, "Password must be at least eight characters."),
});
export const AuthSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Email Required"),
  password: Yup.string().required("Password Required"),
});

export const UserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email.").required("Email required."),
  name: Yup.string().required("Your name is required."),
  password: Yup.string().required("Password required."),
});

export const CustomerSchema = Yup.object().shape({
  name: Yup.string().required("Name required."),
  phone: Yup.string().required("Phone number required."),
  email: Yup.string().email("Invalid email."),
  street: Yup.string().required("Street required!"),
  city: Yup.string().required("City, Town or Village required."),
  state: Yup.string().required("State required."),
  zip: Yup.string().required("Zip code required."),
  notes: Yup.string(),
});

export const ServiceSchema = Yup.object().shape({
  notes: Yup.string(),
});
