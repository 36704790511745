import {
  faCalendar,
  faCalendarWeek,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
const MobileTools = ({
  open,
  done,
  hoverVariant,
  setToggle,
  today,
  week,
  toggle,
  onToday,
  onWeek,
  toggleFilters,
  onReset,
}) => {
  return (
    <div className="mobile-services">
      <div className="status-services">
        <div className="read-out">
          <FontAwesomeIcon icon={faCheck} />
          <div>{done.length > 0 ? done.length : 0}</div>
        </div>
        <div className="read-out">
          <FontAwesomeIcon icon={faCalendar} />
          <div className="pending">{open.length > 0 ? open.length : 0}</div>
        </div>
        <motion.div
          variants={hoverVariant}
          whileHover="hover"
          className="b-filter"
          onClick={toggleFilters}
        >
          <FontAwesomeIcon icon={faCalendarWeek} />
        </motion.div>
      </div>
      <div className="day-filters">
        <motion.div
          variants={hoverVariant}
          whileHover="hover"
          className="bar-btns"
          onClick={() => setToggle((prevToggle) => !prevToggle)}
        >
          <h6>Status</h6>
          {toggle ? (
            <FontAwesomeIcon icon={faCheck} color="#7bb6cf" />
          ) : (
            <FontAwesomeIcon icon={faCalendar} color="#eb5e55" />
          )}
        </motion.div>
        <motion.div
          onClick={() => onToday()}
          variants={hoverVariant}
          whileHover="hover"
          className="bar-btns"
        >
          <h6>Today</h6>
          {today && <div className="mini-dot"></div>}
        </motion.div>
        <motion.div
          onClick={() => onWeek()}
          variants={hoverVariant}
          whileHover="hover"
          className="bar-btns"
        >
          <h6>Current Week</h6>
          {week && <div className="mini-dot"></div>}
        </motion.div>
        <motion.div
          onClick={() => onReset()}
          variants={hoverVariant}
          whileHover="hover"
          className="bar-btns"
        >
          <h6>Reset Filters</h6>
        </motion.div>
      </div>
    </div>
  );
};

export default MobileTools;
