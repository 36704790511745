// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { linkNav } from "../../data/nav";
import { motion } from "framer-motion";

// import { faSignOut, faUserCircle } from "@fortawesome/pro-regular-svg-icons";

const Links = ({ logOut, handleMenuClose }) => {
  return (
    <ul>
      {linkNav.map((link, index) => {
        return (
          <motion.li
            whileHover={{
              backgroundColor: "#407992",
            }}
            whileTap={{
              backgroundColor: "#407992",
            }}
            key={index}
            onClick={() => handleMenuClose()}
          >
            <Link to={link.path}>{link.title}</Link>
          </motion.li>
        );
      })}

      <li onClick={() => logOut()} className="cursor">
        Logout
        {/* <FontAwesomeIcon icon={faSignOut} /> */}
      </li>
      {/* <li>
        <Link to="/account">
          <FontAwesomeIcon icon={faUserCircle} />
        </Link>
      </li> */}
    </ul>
  );
};

export default Links;
