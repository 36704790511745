import PageMotion from "../page-motion";

const Invoices = () => {
  return (
    <PageMotion>
      <div className="page">Invoices system upgrade deploying soon.</div>
    </PageMotion>
  );
};

export default Invoices;
