import { useState } from "react";
import PasswordForm from "../components/Account/password-form";
import ModalPage from "../components/modals/modal_page";

const Security = () => {
  const [password, setPassword] = useState(false);
  return (
    <div className="accounts">
      {password && (
        <ModalPage closeModal={() => setPassword(false)}>
          <div className="title mb">Update Password</div>
          <PasswordForm setPassword={setPassword} />
        </ModalPage>
      )}

      <div className="title mb">Security</div>

      <div className="content-b">
        <div>
          <h4>Password Management</h4>
        </div>
        <div className="btns" onClick={() => setPassword(true)}>
          Update Password
        </div>
      </div>
    </div>
  );
};

export default Security;
