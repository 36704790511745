import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  faWrench,
  faPenAlt,
  faCheck,
  faTrash,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";

import { convertTimezone, formatDate, formatDateTime } from "../../utils/dates";

const ServicesList = ({
  services,
  updateComplete,
  deleteService,
  editService,
  fullService,
}) => {
  const {
    type,
    complete,
    date,
    description,
    id,
    updated_at,
    customers,
    arrive,
    customer_id,
    price,
  } = services;

  return (
    <motion.div className="service">
      <div className="service-info">
        <div className="avatar">
          <FontAwesomeIcon icon={faWrench} size="1x" />
        </div>
        <div className="service-name">
          <div className="name">{type}</div>
          <div className="price">
            Price. {price ? numeral(price).format("$0,0.00") : "No Estimate"}
          </div>
          <div className="date">
            Service date: {arrive ? formatDateTime(arrive) : formatDate(date)}
            <br />
            {arrive ? convertTimezone(arrive) : convertTimezone(date)}
            {/* Service date: {arrive ? formatDate(arrive) : formatDate(date)} */}
          </div>

          <div className="date">Updated At: {formatDateTime(updated_at)}</div>
          {fullService && (
            <div className="customer">
              <Link to={`/customers/${customers.id}`}>{customers.name} </Link> |
              {customers.phone}
            </div>
          )}
          <motion.div className="notes">{description}</motion.div>
        </div>
      </div>
      <div className="status">
        <h5 className={complete === 0 ? "pending" : "complete"}>
          {complete === 0 ? "Pending" : "Complete"}
        </h5>
        <div className="icon">
          <span onClick={() => updateComplete(id, complete)}>
            <FontAwesomeIcon
              icon={faCheck}
              color={complete === 0 ? "#eb5e55" : "#61C9A8"}
              size="1x"
            />
          </span>

          <span onClick={() => editService(services)}>
            <FontAwesomeIcon icon={faPenAlt} color="#8693AB" size="1x" />
          </span>

          <span
            onClick={() => deleteService({ id, complete, type, customer_id })}
          >
            {complete === 1 ? (
              <FontAwesomeIcon icon={faBan} color="#30343F" size="1x" />
            ) : (
              <FontAwesomeIcon icon={faTrash} color="#FF6666" size="1x" />
            )}
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default ServicesList;
