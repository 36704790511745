import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ServicesList from "../components/services/services-list";
import ModalPage from "../components/modals/modal_page";
import EditCustomer from "../components/customers/edit-customer";
import EditService from "../components/customers/edit-services";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  faMapMarkerAlt,
  faMobile,
  faUserCircle,
  faUser,
  faEnvelope,
  faPenAlt,
  faTools,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCustomer,
  updateServiceComplete,
  deleteCustomerServiceAction,
} from "../actions/shared";
import NewService from "../components/services/new-service";
import Loader from "../components/shared/Loader";
import { clearCustomer } from "../actions/customers";
import PageMotion from "../page-motion";
import { Error } from "../components/toasts/toasts";
import {
  formatDate,
  // formatDayDate,
  // formatDateTimeSQL,
  // formatDateTimeNoTime,
} from "../utils/dates";

const Customer = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [updateCustomer, setUpdateCustomer] = useState(false);
  const [statusServices, setStatusServices] = useState(false);
  const [newServices, setNewService] = useState(false);
  const [service, setService] = useState({});

  const id = match.params.id;
  const { loading, customer, services } = useSelector(
    (state) => state.customerReducer
  );

  useEffect(() => {
    dispatch(getCustomer(id));
    return () => {
      dispatch(clearCustomer());
    };
  }, [dispatch, id]);

  //service list condition
  const fullService = false;

  const back = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(clearCustomer());
    }, 1000);
  };

  //handle update complete
  const updateComplete = (id, complete) => {
    let status = complete === 0 ? 1 : 0;

    dispatch(updateServiceComplete({ id, status }));
  };
  //handle update complete
  const deleteService = (status) => {
    if (status.complete === 1) {
      toast.error("Completed services can not be destroyed.", {
        autoClose: 8000,
      });
      return;
    }
    const { id, type, customer_id } = status;
    const payload = {
      id,
      type,
      customer_id,
    };

    toast.error(
      <Error destroy={() => dispatch(deleteCustomerServiceAction(payload))} />,
      {
        position: toast.POSITION.CENTER,
        autoClose: 10000,
      }
    );
  };
  const handleUpdateCustomer = () => {
    setUpdateCustomer(true);
  };

  const editService = (services) => {
    console.log(services);
    setService(services);
    setStatusServices(true);
  };

  const { name, city, email, join, phone, state, street, zip } = customer;

  const newJoin = join ? new Date(join.slice(0, 10)) : join;

  const finalJoin = newJoin ? formatDate(newJoin) : "No Date";

  return (
    <PageMotion>
      {updateCustomer && (
        <ModalPage closeModal={() => setUpdateCustomer(false)}>
          <EditCustomer
            setUpdateCustomer={setUpdateCustomer}
            customer={customer}
          />
        </ModalPage>
      )}
      {statusServices && (
        <ModalPage closeModal={() => setStatusServices(false)}>
          <EditService
            service={service}
            setStatusServices={setStatusServices}
          />
        </ModalPage>
      )}

      {newServices && (
        <ModalPage closeModal={() => setNewService(false)}>
          <NewService setNewService={setNewService} id={id} />
        </ModalPage>
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div onClick={() => back()} className="back">
            <FontAwesomeIcon icon={faChevronLeft} size="1x" />
          </div>
          <div className="content">
            <div className="profile">
              <div className="profile-box">
                <div className="profile-avatar">
                  <FontAwesomeIcon icon={faUserCircle} size="6x" />
                </div>
                <div className="name">
                  <h3>{name}</h3>
                </div>

                <div className="phone">
                  <div>
                    <FontAwesomeIcon icon={faMobile} size="1x" />
                    <span>Phone</span>
                  </div>
                  <div>{phone}</div>
                </div>
                <div className="phone">
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} size="1x" />
                    <span>Email</span>
                  </div>
                  <div>{email}</div>
                </div>
                <div className="location">
                  <div>
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" />
                    <span> {street}</span>
                  </div>
                  <div>
                    {city} {state},{zip}
                  </div>
                </div>
                <div className="member">
                  <div>
                    <FontAwesomeIcon icon={faUser} size="1x" />
                    <span>Customer since</span>
                  </div>
                  <div>{finalJoin}</div>
                </div>
                <div
                  onClick={() => handleUpdateCustomer()}
                  className="edit_btn cursor"
                >
                  <span>Edit {name}</span>
                  <FontAwesomeIcon icon={faPenAlt} size="1x" />
                </div>
              </div>
            </div>
            <div className="service_profile">
              <div className="service_box">
                <div className="service-header">
                  <h3>Services</h3>
                  <div
                    onClick={() => setNewService(true)}
                    className="circle-btn cursor"
                  >
                    <FontAwesomeIcon icon={faTools} size="1x" />
                  </div>
                </div>

                {services
                  ? services.map((serv) => (
                      <ServicesList
                        key={serv.id}
                        services={serv}
                        updateComplete={updateComplete}
                        deleteService={deleteService}
                        editService={editService}
                        fullService={fullService}
                      />
                    ))
                  : "No services"}
              </div>
            </div>
          </div>
        </>
      )}
    </PageMotion>
  );
};

export default Customer;
