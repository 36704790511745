import { client } from "../apollo";

import {
  Customers,
  Customer,
  FindCustomers,
  UpdateCustomer,
  CreateCustomer,
} from "../graphql/customers";
import { UpdateService, NewService, DeleteService } from "../graphql/services";
import {
  getAllCustomersAction,
  getCustomerAction,
  loading,
  toggleComplete,
  updateCustomer,
  deleteCustomerService,
  updateCustomerService,
  findCustomerAction,
  newCustomer,
  newService,
} from "../actions/customers";

//customer action creator

export const newCustomerAction = (payload, info) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: CreateCustomer,
      variables: payload,
    });

    dispatch(newCustomer(data.createCustomer));
    dispatch(updateUI(info));
  } catch (error) {
    console.error("error", error);
  }
};

export const updateUI = (info) => async (dispatch) => {
  const { page, status } = info;
  try {
    dispatch(loading(true));
    const { data } = await client.query({
      query: Customers,
      fetchPolicy: "network-only",

      variables: {
        first: page,
        page: status,
      },
    });
    dispatch(loading(false));

    dispatch(getAllCustomersAction(data.customers));
  } catch (error) {
    console.error("error", error);
  }
};

export const getAllCustomers = (info) => async (dispatch) => {
  const { page, status } = info;
  try {
    dispatch(loading(true));
    const { data } = await client.query({
      query: Customers,
      fetchPolicy: "no-cache",

      variables: {
        first: page,
        page: status,
      },
    });
    dispatch(loading(false));

    dispatch(getAllCustomersAction(data.customers));
  } catch (error) {
    console.error("error", error);
  }
};
//get customer
export const getCustomer = (id) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const { data } = await client.query({
      query: Customer,
      fetchPolicy: "no-cache",

      variables: {
        id: id,
      },
    });
    dispatch(loading(false));

    dispatch(getCustomerAction(data.customer));
  } catch (error) {
    console.error("error", error);
  }
};

//search customers
export const findCustomers = (customer) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const { data } = await client.query({
      query: FindCustomers,
      fetchPolicy: "network-only",
      variables: {
        name: customer,
      },
    });
    dispatch(loading(false));

    dispatch(findCustomerAction(data.findCustomer));
  } catch (error) {
    console.error("error", error);
  }
};

//update services
export const updateServiceComplete = (payload) => async (dispatch) => {
  dispatch(toggleComplete(payload));
  try {
    //dispatch(loading(true));
    await client.mutate({
      mutation: UpdateService,
      variables: {
        id: payload.id,
        complete: payload.status,
      },
    });
  } catch (error) {
    console.error("error", error);
    dispatch(toggleComplete(payload));
  }
};

//update services
export const updateCustomerAction = (payload) => async (dispatch) => {
  dispatch(updateCustomer(payload));
  try {
    await client.mutate({
      mutation: UpdateCustomer,
      variables: payload,
    });
  } catch (error) {
    console.error("error", error);
  }
};
//update services

export const newServiceAction = (payload) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: NewService,
      variables: payload,
    });
    //console.log(data.createCustomer);
    dispatch(newService(data.newServices));
  } catch (error) {
    console.error("error", error);
  }
};
export const updateCustomerServiceAction = (payload) => async (dispatch) => {
  dispatch(updateCustomerService(payload));
  try {
    await client.mutate({
      mutation: UpdateService,
      variables: payload,
    });
  } catch (error) {
    console.error("error", error);
  }
};

export const deleteCustomerServiceAction = (status) => async (dispatch) => {
  dispatch(deleteCustomerService(status.id));
  try {
    await client.mutate({
      mutation: DeleteService,
      variables: status,
    });
  } catch (error) {
    console.error("error", error);
  }
};
