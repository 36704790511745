import gql from "graphql-tag";

export const TodayCustomers = gql`
  query {
    todayCustomers {
      id
      name
      phone
      join
      updated_at

      services {
        id
        type
        complete
        date
        arrive
        price
      }
    }
  }
`;

export const WeekCustomers = gql`
  query {
    weekCustomers {
      id
      name
      phone
      join
      updated_at
      services {
        id
        type
        complete
        date
        arrive
        price
      }
    }
  }
`;

export const ShowAll = gql`
  query {
    showAll {
      id
      name
      phone
      join
      updated_at
      services {
        id
        type
        date
        complete
        arrive
        price
      }
    }
  }
`;

export const FindCustomers = gql`
  query($name: String!) {
    findCustomer(name: $name) {
      id
      name
      phone
      email
      street
      city
      state
      zip
      join

      services {
        id
        type
        date
        description
        complete
        updated_at
        arrive
        price
      }
    }
  }
`;

export const Customers = gql`
  query($first: Int!, $page: Int!) {
    customers(first: $first, page: $page) {
      data {
        id
        name
        phone
        email
        street
        city
        state
        zip
        join
        updated_at
        services {
          id
          type
          date
          description
          complete
          updated_at
          arrive
          customer_id
          price
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        total
      }
    }
  }
`;

export const Customer = gql`
  query($id: ID!) {
    customer(id: $id) {
      id
      name
      phone
      email
      street
      city
      state
      zip
      join
      notes
      services {
        id
        type
        date
        description
        complete
        updated_at
        arrive
        customer_id
        price
      }
    }
  }
`;

export const CreateCustomer = gql`
  mutation(
    $name: String!
    $phone: String
    $email: String
    $street: String
    $city: String
    $zip: String
    $state: String
    $join: DateTime
    $notes: String
  ) {
    createCustomer(
      input: {
        name: $name
        phone: $phone
        email: $email
        street: $street
        city: $city
        zip: $zip
        state: $state
        join: $join
        notes: $notes
      }
    ) {
      id
      name
      phone
      email
      street
      city
      state
      zip
      join

      services {
        id
        type
        date
        description
        complete
        updated_at
        arrive
        price
      }
    }
  }
`;

export const UpdateCustomer = gql`
  mutation(
    $id: ID!
    $name: String
    $phone: String
    $email: String
    $street: String
    $city: String
    $state: String
    $zip: String
    $join: DateTime
    $notes: String
  ) {
    updateCustomer(
      input: {
        name: $name
        phone: $phone
        email: $email
        street: $street
        city: $city
        state: $state
        zip: $zip
        id: $id
        join: $join
        notes: $notes
      }
    ) {
      id
      name
      email
      street
      phone
      city
      state
      zip
      join
      notes
    }
  }
`;
