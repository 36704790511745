export const variants = {
  initial: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    opacity: 0,
    x: 20,
  },
};
