import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomerAction } from "../../actions/shared";
import { motion } from "framer-motion";

import DatePicker from "react-date-picker";
import { CustomerSchema } from "../../schema/validations";
import { Formik, Form, Field } from "formik";
import { formatDateTimeSQL } from "../../utils/dates";
const EditCustomer = ({ customer, setUpdateCustomer }) => {
  const dispatch = useDispatch();

  const {
    name,
    city,
    email,
    phone,
    street,
    state,
    zip,
    join,
    notes,
  } = customer;

  const validDate = (date) => {
    let testDate = new Date(date);
    //refactor to testDate.isValid()
    if (testDate.toString() === "Invalid Date") {
      return new Date();
    }
    if (!testDate instanceof Date) {
      return new Date();
    }

    return new Date(date);
  };

  const validJoin = validDate(join);

  const [dte, onChange] = useState(validJoin);
  const onUpdateCustomer = (values) => {
    const id = customer.id;
    const payload = {
      id,
      name: values.name,
      phone: values.phone,
      email: values.email,
      street: values.street,
      city: values.city,
      state: values.state,
      zip: values.zip,
      notes: values.notes,
      // join: dte,
      join: formatDateTimeSQL(dte),
    };

    dispatch(updateCustomerAction(payload));
    setUpdateCustomer(false);
  };

  return (
    <div className="form-page">
      <Formik
        initialValues={{
          name,
          phone,
          email,
          street,
          city,
          state,
          zip,
          join,
          notes,
        }}
        validationSchema={CustomerSchema}
        onSubmit={(values) => {
          onUpdateCustomer(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="input-control">
              <Field type="text" placeholder="Name" name="name" />
              {errors.name && touched.name ? (
                <div className="form-errors">{errors.name}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field type="text" placeholder="Phone" name="phone" />
              {errors.phone && touched.phone ? (
                <div className="form-errors">{errors.phone}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="Email" type="text" name="email" />
              {errors.email && touched.email ? (
                <div className="form-errors">{errors.email}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="Street" type="text" name="street" />
              {errors.street && touched.street ? (
                <div className="form-errors">{errors.street}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="City" type="text" name="city" />
              {errors.city && touched.city ? (
                <div className="form-errors">{errors.city}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="State" type="text" name="state" />
              {errors.state && touched.state ? (
                <div className="form-errors">{errors.state}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="Zip" type="text" name="zip" />
              {errors.zip && touched.zip ? (
                <div className="form-errors">{errors.zip}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field
                as="textarea"
                placeholder="Notes"
                name="notes"
                cols="30"
                rows="3"
              ></Field>
              {errors.notes && touched.notes ? (
                <div className="form-errors">{errors.notes}</div>
              ) : null}
            </div>
            <div>
              <DatePicker onChange={onChange} value={dte} />
            </div>
            <motion.button
              type="submit"
              whileHover={{
                scale: 0.9,
                color: "#f8e112",
              }}
              transition={{
                type: "spring",
                stiffness: 300,
              }}
              className="form-btn cursor"
            >
              Update, {name}
            </motion.button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCustomer;
