import {
  faToolbox,
  faUser,
  faWrench,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  formatTime,
  formatDayDate,
  getCurrentDateFormat,
} from "../../utils/dates";
const Detail = ({ job, jobs, onComplete }) => {
  const { customers, type, arrive, id, complete } = job;

  const currentJobs = jobs.filter((j) => j.complete === 0).length;

  return (
    <>
      <div className="details-3">
        <div className="detail">
          <div className="content-detail">
            <div className="avatar">
              <FontAwesomeIcon icon={faUser} size="3x" />
            </div>
            <div className="cus-name">{customers.name}</div>
            <div className="sub-title">{customers.phone}</div>
            <div className="sub-title">{customers.street}</div>
            <div className="sub-title">
              {customers.city}, {customers.state}
            </div>
            <div
              onClick={() => onComplete(id, complete)}
              className="detail-btn"
            >
              Complete
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="content-detail">
            <div className="avatar">
              <FontAwesomeIcon icon={faWrench} size="3x" />
            </div>
            <div className="cus-name">{type}</div>
            <div className="sub-title">Appointment Day</div>
            <div className="sub-title">{formatDayDate(arrive)}</div>
            <div className="sub-title">Arrival Time</div>
            <div className="sub-title">{formatTime(arrive)}</div>
          </div>
        </div>
        <div className="detail">
          <div className="content-detail">
            <div className="avatar">
              <FontAwesomeIcon icon={faToolbox} size="3x" />
            </div>
            <div className="cus-name">Total Jobs</div>
            <div className="sub-title">Today</div>
            {/* <div className="sub-title">{formatDayDate(arrive)}</div> */}

            <div className="sub-title">{getCurrentDateFormat()}</div>
            <div className="large-title">{currentJobs}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
