//action creators
import { client } from "../../../apollo";
import {
  getServices,
  toggleCompleteServices,
  updateServices,
  deleteService,
  loading,
} from "../../services";

import {
  DeleteService,
  UpdateService,
  Services,
  ChangeMonth,
  MonthRange,
  CustomDateRange,
  Today,
  Week,
} from "../../../graphql/services";

export const getServicesAction = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const { data } = await client.query({
      query: Services,
      fetchPolicy: "no-cache",
    });
    dispatch(getServices(data.services));
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
    console.log(error);
  }
};

export const todayAction = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const { data } = await client.query({
      query: Today,
      fetchPolicy: "network-only",
    });

    dispatch(getServices(data.today));
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
    console.log(error);
  }
};
export const weekAction = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const { data } = await client.query({
      query: Week,
    });
    dispatch(getServices(data.week));
    dispatch(loading(false));
  } catch (error) {
    dispatch(loading(false));
    console.log(error);
  }
};
export const getMonthAction = (month) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: ChangeMonth,
      variables: { month: month.value },
    });
    dispatch(getServices(data.changeMonth));
  } catch (error) {
    console.log(error);
  }
};
export const getMonthRangeAction = (payload) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: MonthRange,
      variables: payload,
    });
    dispatch(getServices(data.monthRange));
  } catch (error) {
    console.log(error);
  }
};
export const getCustomDateRangeAction = (payload) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: CustomDateRange,
      variables: payload,
    });
    dispatch(getServices(data.customDateRange));
  } catch (error) {
    console.log(error);
  }
};

export const toggleCompleteServicesAction = (payload) => async (dispatch) => {
  dispatch(toggleCompleteServices(payload));

  try {
    await client.mutate({
      mutation: UpdateService,
      variables: {
        id: payload.id,
        complete: payload.status,
      },
    });

    //dispatch(getServices(data.services));
  } catch (error) {
    dispatch(toggleCompleteServices(payload));
    console.log(error);
  }
};

export const updateServiceAction = (payload) => async (dispatch) => {
  dispatch(updateServices(payload));
  try {
    await client.mutate({
      mutation: UpdateService,
      variables: payload,
    });
  } catch (error) {
    console.error("error", error);
  }
};

export const deleteCustomerServiceAction = (status) => async (dispatch) => {
  // dispatch(deleteCustomerService(id));
  console.log(status);
  // try {
  //   const { data } = await client.mutate({
  //     mutation: DeleteService,
  //     variables: status,
  //   });
  // } catch (error) {
  //   console.error("error", error);
  // }
};

export const deleteServiceAction = (status) => async (dispatch) => {
  dispatch(deleteService(status.id));
  try {
    await client.mutate({
      mutation: DeleteService,
      variables: status,
    });
  } catch (error) {
    console.error("error", error);
  }
};
