import { combineReducers } from "redux";
import { customerReducer } from "./customers";
import { services } from "./services";
import { auth } from "./auth";
import { overview } from "./overview";

export default combineReducers({
  customerReducer,
  services,
  auth,
  overview,
});
