import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomerServiceAction } from "../../actions/shared";
import { updateServiceAction } from "../../actions/creators/services/service_creators";
import { motion } from "framer-motion";
import { faCalendarStar, faPenAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  formatDateForSQL,
  addDaysToDate,
  formatDateTimeSQL,
  formatDateTime,
} from "../../utils/dates";
import { ServiceSchema } from "../../schema/validations";
import { Formik, Form, Field } from "formik";

import { servicesOptions } from "../../utils/options";
const EditService = ({ service, setStatusServices, fullService }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState({
    label: service.type,
    value: service.type,
  });
  const appointment =
    service.arrive !== null
      ? new Date(service.arrive)
      : addDaysToDate(new Date(service.date), 1);
  const [value, onChange] = useState(new Date());

  const onUpdateService = (values) => {
    if (type.value === undefined) {
      toast.error("Service type must be selected to move forward. ", {
        position: toast.POSITION.CENTER,
        autoClose: 5000,
      });
      return;
    }
    if (!value) {
      toast.error(
        "You have entered an invalid date. Please check date and of service.",
        {
          position: toast.POSITION.CENTER,
          autoClose: 5000,
        }
      );
      return;
    }

    const id = service.id;
    const payload = {
      id,
      type: type.value,
      price: parseFloat(values.price),
      arrive: formatDateTimeSQL(value),
      date: formatDateForSQL(value),
      description: values.description,
    };

    if (fullService) {
      dispatch(updateServiceAction(payload));
    } else {
      dispatch(updateCustomerServiceAction(payload));
    }

    setStatusServices(false);
  };

  return (
    <div className="form-page">
      <Formik
        initialValues={{
          description: service.description,
          price: service.price ? service.price : "No Estimate",
        }}
        validationSchema={ServiceSchema}
        onSubmit={(values) => {
          onUpdateService(values);
        }}
      >
        <Form>
          <div className="input-control">
            <h4>Current Service: {type.value}</h4>
            <Select options={servicesOptions} onChange={setType} />
          </div>
          <div className="input-date">
            <DateTimePicker onChange={onChange} value={value} />
            <div className="old">
              <FontAwesomeIcon icon={faCalendarStar} />
              <span>
                Current appointment time, {formatDateTime(appointment)}
              </span>
            </div>
          </div>
          <div className="input-control">
            <Field
              as="input"
              placeholder="Enter Estimate, Ex. 1234.00 no dollar sign."
              name="price"
            ></Field>
          </div>
          <div className="input-control">
            <Field
              as="textarea"
              placeholder="Description"
              name="description"
              cols="30"
              rows="10"
            ></Field>
          </div>

          <motion.button
            type="submit"
            whileHover={{
              scale: 0.9,
              color: "#f8e112",
            }}
            transition={{
              type: "spring",
              stiffness: 300,
            }}
            className="form-btn cursor"
          >
            <span> Edit Service</span>
            <FontAwesomeIcon icon={faPenAlt} color="#FF6666" size="1x" />
          </motion.button>
        </Form>
      </Formik>
    </div>
  );
};

export default EditService;
