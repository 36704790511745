import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PageMotion from "../page-motion";

import JobList from "../components/overview/job-list";
import { jobCompleteServicesAction } from "../actions/overview.js";

import { todayAction } from "../actions/overview";
import {
  faToolbox,
  faTools,
  faUser,
  faUserAlt,
  faWrench,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Detail from "../components/overview/detail";
import { oneJob } from "../actions/overview";
import Loader from "../components/shared/Loader";
import { statsAction } from "../actions/overview";
import Stats from "../components/overview/stats";

const Overview = () => {
  const dispatch = useDispatch();

  const { jobs, job, loading, stats } = useSelector((state) => state.overview);
  //const { stats, errors } = useSelector((state) => state.overview);

  //LOAD ALL JOBS TO STATE
  useEffect(() => {
    dispatch(todayAction());
    dispatch(statsAction());
  }, [dispatch]);

  useEffect(() => {
    setFirstJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  const setFirstJob = () => {
    if (jobs.length > 0) {
      const pending = jobs.filter((j) => j.complete === 0);
      if (pending.length > 0) {
        const id = pending[0].id;
        dispatch(oneJob(id));
      }

      return;
    }
  };

  const findDetail = (id) => {
    dispatch(oneJob(id));
  };

  const onComplete = (id, complete) => {
    let status = complete === 0 ? 1 : 0;
    dispatch(jobCompleteServicesAction({ id, status }));

    // if (complete === 1) {
    //   toast.warn(
    //     <Warning
    //       btn="Reverse"
    //       icn={faCheck}
    //       colr="#fff"
    //       onAction={() =>
    //         dispatch(toggleCompleteServicesAction({ id, status }))
    //       }
    //     >
    //       <h3>Warning</h3>
    //       <p>
    //         This action will change your completed service back to pending.
    //         Futher, any auto generated bookings or appointments connected to
    //         this service will be destroyed.
    //       </p>
    //       <p>To be proceed, click the REVERSE button below.</p>
    //     </Warning>
    //   );
    //   return;
  };

  return (
    <PageMotion>
      <div className="content-overview">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="mobile-stats">
              {stats.hasOwnProperty("installs") && (
                <>
                  <div className="row-mobile">
                    <Stats
                      name="Installs"
                      stats={stats.installs}
                      icon={faToolbox}
                    />
                    <Stats name="Jobs" stats={stats.jobs} icon={faTools} />
                    <Stats
                      name="Pending"
                      stats={stats.pending}
                      icon={faWrench}
                    />
                  </div>
                  <div className="row-mobile">
                    <Stats
                      name="New"
                      stats={stats.new_customers}
                      icon={faUser}
                    />
                    <Stats
                      name="Customers"
                      stats={stats.customers}
                      icon={faUserAlt}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="jobs">
              <h2>Jobs Due Today</h2>

              <div className="list">
                {jobs.length > 0 ? (
                  jobs
                    .slice(0, 4)
                    .map((services) => (
                      <JobList
                        key={services.id}
                        services={services}
                        findDetail={findDetail}
                      />
                    ))
                ) : (
                  <div>
                    <h3>No Jobs Today</h3>
                  </div>
                )}
              </div>
            </div>
            <div className="details">
              {job.hasOwnProperty("id") && (
                <Detail
                  job={job}
                  jobs={jobs}
                  findDetail={findDetail}
                  onComplete={onComplete}
                />
              )}
            </div>

            {/* Right bar*/}
            <div className="right-side">
              <div className="stats-box">
                <h3>Monthly Stats</h3>
                {stats.hasOwnProperty("installs") && (
                  <>
                    <div className="stats">
                      <div className="stats-data">
                        <div className="stats-number">{stats.installs}</div>
                        <div className="icon">
                          <FontAwesomeIcon icon={faToolbox} size="1x" />
                        </div>
                      </div>
                      <div className="stats-name">Installations</div>
                    </div>
                    <div className="stats">
                      <div className="stats-data">
                        <div className="stats-number">{stats.pending}</div>
                        <div className="icon">
                          <FontAwesomeIcon icon={faWrench} size="1x" />
                        </div>
                      </div>
                      <div className="stats-name">Jobs pending</div>
                    </div>
                    <div className="stats">
                      <div className="stats-data">
                        <div className="stats-number">{stats.jobs}</div>
                        <div className="icon">
                          <FontAwesomeIcon icon={faTools} size="1x" />
                        </div>
                      </div>
                      <div className="stats-name">Jobs Completed</div>
                    </div>
                    <div className="stats">
                      <div className="stats-data">
                        <div className="stats-number">
                          {stats.new_customers}
                        </div>
                        <div className="icon">
                          <FontAwesomeIcon icon={faUser} size="1x" />
                        </div>
                      </div>
                      <div className="stats-name">New Customers</div>
                    </div>
                    <div className="stats">
                      <div className="stats-data">
                        <div className="stats-number">{stats.customers}</div>
                        <div className="icon">
                          <FontAwesomeIcon icon={faUser} size="1x" />
                        </div>
                      </div>
                      <div className="stats-name">Total Customers</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </PageMotion>
  );
};

export default Overview;
