//service reducer
import { ERRORS, LOGOUT, IS_AUTH, UPDATE_USER } from "../types/types";

const initialState = {
  isAuth: sessionStorage.getItem("isAuth") || false,
  token: sessionStorage.getItem("token") || null,
  user: JSON.parse(sessionStorage.getItem("user")) || {},

  loading: false,
  errors: null,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTH:
      const { login } = action.payload;
      const { email, created_at, name, token } = login;
      const user = {
        email,
        created_at,
        name,
      };
      return {
        ...state,
        isAuth: token ? true : false,
        token: token,
        user: user,
      };

    case UPDATE_USER:
      return {
        ...state,

        user: action.user,
      };

    case LOGOUT:
      return {
        ...state,
        isAuth: false,
        token: null,
        user: {},
      };

    case ERRORS:
      return {
        ...state,
        errors: action.error,
      };

    default:
      return state;
  }
};
