import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { motion } from "framer-motion";
import { hoverVariant } from "../../variants/bar";

export const Error = ({ closeToast, destroy }) => {
  return (
    <div>
      <p>Danger, this action can not be undone. Proceed with caution.</p>
      <p>If you would like to proceed with this action, press destroy below.</p>
      <motion.div
        variants={hoverVariant}
        whileHover="hover"
        className="btn-toast"
        onClick={destroy}
      >
        <FontAwesomeIcon icon={faTrash} color="#fff" />
        <span>Destroy</span>
      </motion.div>
    </div>
  );
};
export const Warning = ({ closeToast, onAction, children, btn, icn, colr }) => {
  return (
    <div>
      {children}
      <motion.div
        variants={hoverVariant}
        whileHover="hover"
        className="btn-toast"
        onClick={onAction}
      >
        <FontAwesomeIcon icon={icn} color={colr} />
        <span>{btn}</span>
      </motion.div>
    </div>
  );
};
