//dates
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import formatISO9075 from "date-fns/formatISO9075";

export const convertTimezone = (date) => {
  // const newDate = new Date(date).toLocaleString("en-US", {
  //   timeZone: "America/New_York",
  //   timeZoneName: "long",
  // });

  const newDate = date.replace(/ /g, "T");
  const test = format(new Date(newDate), "LLLL do");
  console.log(newDate);
  console.log(test);
  return test;
};

const validDate = (date) => {
  let testDate = new Date(date);
  //refactor to testDate.isValid()
  if (testDate.toString() === "Invalid Date") {
    return new Date();
  }
  if (!testDate instanceof Date) {
    return new Date();
  }

  return date;
};

//current date
export const getCurrentDateFormat = () => {
  const result = format(new Date(), "LLLL do yyyy");
  return result;
};

//time
export const formatTime = (date) => {
  const valid = validDate(date);
  const result = format(new Date(valid), "hh:mm:ss a");
  return result;
};
export const formatDayDate = (date) => {
  const valid = validDate(date);
  return format(new Date(valid), "LLLL do");
};

//distance to now
export const distanceToNow = (date) => {
  const valid = validDate(date);
  return formatDistanceToNow(new Date(valid));
};

//basic format
export const getCurrentMonthWords = () => {
  return format(new Date(), "LLLL-yyyy");
};

//basic date no time Month letter short

export const formatDate = (date) => {
  const valid = validDate(date);
  // const formats = valid
  //   ? format(new Date(date), "LLL-do-yyyy")
  //   : format(new Date(), "LLL-do-yyyy");

  const formats = format(new Date(valid), "LLL-do-yyyy");
  // return format(addDays(new Date(date), 1), "LLL-do-yyyy");
  return formats;
};

//basic date with time Month letter short

export const formatDateTime = (date) => {
  const valid = validDate(date);
  return format(new Date(valid), "LL/dd/yy hh:mm a");
};
export const formatDateTimeNoTime = (date) => {
  const valid = validDate(date);
  return format(new Date(valid), "LLL-do-yyyy");
};

//sql datetime

export const formatDateTimeSQL = (date) => {
  const valid = validDate(date);
  return formatISO9075(new Date(valid));
};
//format datepicker

export const formatForDatePicker = (date) => {
  const valid = validDate(date);
  return format(new Date(valid), "yyyy-MM-dd hh:mm:ss");
};
//format sql dates
export const formatDateForSQL = (date) => {
  const valid = validDate(date);
  return format(new Date(valid), "yyyy-MM-dd");
};

//add days
export const addDaysToDate = (date, num) => {
  return addDays(date, num);
};
