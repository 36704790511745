import { Link } from "react-router-dom";
import { faWrench } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import { formatDate, formatDateTime } from "../../utils/dates";

const JobList = ({ services, findDetail }) => {
  const {
    type,

    arrive,
    date,
    id,
    price,
    customers,
  } = services;

  return (
    <div className="job-list">
      <div className="service">
        <div className="service-info">
          <div className="avatar">
            <FontAwesomeIcon icon={faWrench} size="1x" />
          </div>
          <div className="name">
            <h3>{type}</h3>
            <div className="price">
              Price. {price ? numeral(price).format("$0,0.00") : "No Estimate"}
            </div>
            <div className="date">
              Job date: {arrive ? formatDateTime(arrive) : formatDate(date)}
            </div>

            <div className="customer">
              <Link to={`/customers/${customers.id}`}>{customers.name} </Link> |
              {customers.phone}
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="detail-btn" onClick={() => findDetail(id)}>
            Detail
          </div>
        </div>
        {/* <div className="status">
          <h5 className={complete === 0 ? "pending" : "complete"}>
            {complete === 0 ? "Pending" : "Complete"}
          </h5>
          <div className="icon">
            <span onClick={() => updateComplete(id, complete)}>
              <FontAwesomeIcon
                icon={faCheck}
                color={complete === 0 ? "#eb5e55" : "#61C9A8"}
                size="1x"
              />
            </span>

            <span onClick={() => deleteService({ id, complete })}>
              {complete === 1 ? (
                <FontAwesomeIcon icon={faBan} color="#30343F" size="1x" />
              ) : (
                <FontAwesomeIcon icon={faTrash} color="#FF6666" size="1x" />
              )}
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default JobList;
