import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { pageCount } from "../actions/customers";
import { customer } from "../utils/options";

const Settings = ({ page }) => {
  const dispatch = useDispatch();

  const [customers, setCustomer] = useState(5);
  const firstTimeRender = useRef(true);

  const onChangeCustomer = (value) => {
    setCustomer(value);
  };
  useEffect(() => {
    if (!firstTimeRender.current) {
      dispatch(pageCount(customers));
      localStorage.setItem("page", customers.value);
    }
  }, [customers, dispatch]);
  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  return (
    <>
      <div className="title mb">Settings</div>
      <div className="content-b">
        <h4>Set Customers Per Page</h4>
        <h5>Current: {page}</h5>
        <Select
          options={customer}
          placeholder="Select Customer Per Page"
          onChange={onChangeCustomer}
        />
      </div>
    </>
  );
};

export default Settings;
