import { motion } from "framer-motion";
import { containerVariants } from "./variants/pages";

const ListMotion = ({ children }) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

export default ListMotion;
