import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  faBars,
  faSearch,
  faSignOut,
  faTimes,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { variants } from "../../variants/toolbars";
import { findCustomers, getAllCustomers } from "../../actions/shared";
import { logoutCreator } from "../../actions/auth";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { menuAction } from "../../actions/overview";

const Toolbar = () => {
  //use dispatch

  const dispatch = useDispatch();
  const location = useLocation();
  const firstUpdate = useRef(true);

  //redux
  const { page, currentPage } = useSelector((state) => state.customerReducer);
  // const { menu } = useSelector((state) => state.overview);

  //search state
  const [search, setSearch] = useState("");
  // const [reset, setReset] = useState(false);
  const [pages, setPages] = useState(location.pathname);
  // const [error, setError] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setPages(location.pathname);
  }, [location]);
  //form handler

  const searchHandler = (e) => {
    if (e === "") {
      refreshHandler();
    }

    debounceSearch(e);
  };
  //debounce function with closure

  const debounceSearch = useRef(
    debounce((nextValue) => sendToDb(nextValue), 300)
  ).current;

  const sendToDb = (value) => {
    if (value === "") {
      return;
    }
    dispatch(findCustomers(value));
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    searchHandler(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  //reset customer state
  const refreshHandler = () => {
    const info = {
      page,
      status: currentPage,
    };
    dispatch(getAllCustomers(info));
    // setReset(false);
  };

  const clearSearch = () => {
    setSearch("");
    setActive(false);
    refreshHandler();
  };

  const logOut = () => {
    toast.dark("Guardian is logging you out, please wait...", {
      autoClose: 5000,
      onClose: () => dispatch(logoutCreator()),
    });
  };

  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = () => {
    setActive(false);
  };

  const handleMenuClick = () => {
    dispatch(menuAction());
  };

  return (
    <div className="toolbar">
      <div className="tool-bar-content">
        <div className="left-toolbar">
          <div className="logo">
            Guardian <span>Angel</span>
          </div>
          <div className="mobile-logo">
            G<span>A</span>
          </div>

          <AnimatePresence>
            <motion.div
              className="search"
              animate={pages === "/customers" ? "open" : "closed"}
              variants={variants}
              initial={false}
            >
              <motion.div
                initial={false}
                whileHover={{
                  color: "#7bb6cf",
                }}
                whileTap={{ scale: 0.9 }}
                className="icon"
                onClick={() => clearSearch()}
              >
                {!active ? (
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                ) : (
                  <FontAwesomeIcon icon={faTimes} size="1x" />
                )}
              </motion.div>
              <motion.input
                value={search}
                type="text"
                placeholder="Search"
                // className={error ? "error" : "good"}
                onChange={(e) => setSearch(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </motion.div>
          </AnimatePresence>
          <div className="mobile-menu">
            <div className="menu-click" onClick={() => handleMenuClick()}>
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
          {/* <motion.div
            className="icon cursor"
            animate={reset ? "open" : "closed"}
            variants={variants}
            initial={false}
            whileHover={{
              color: "#7bb6cf",
            }}
            whileTap={{ scale: 0.9 }}
            onClick={() => refreshHandler()}
          >
            <FontAwesomeIcon icon={faRedoAlt} size="1x" />
          </motion.div> */}
        </div>

        <div className="tool-items">
          <ul>
            <li>
              <Link to="/overview">Home</Link>
            </li>
            <li>
              <Link to="/customers">Customers</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/invoices">Invoices</Link>
            </li>
            <li>
              <Link to="/calendar">Calendar</Link>
            </li>
            <li onClick={() => logOut()} className="cursor">
              <FontAwesomeIcon icon={faSignOut} />
            </li>
            <li>
              <Link to="/account">
                <FontAwesomeIcon icon={faUserCircle} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
