// import { ApolloClient } from "apollo-client";
// import { WebSocketLink } from "apollo-link-ws";
// import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink, concat } from "apollo-link";
import { createHttpLink } from "apollo-link-http";

// const wsLink = new WebSocketLink({
//   uri: "ws://guard.test/graphql",
//   options: {
//     reconnect: true,
//   },
// });

//HTTPS Contection

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URI,
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}` || null,
    },
  });
  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});
