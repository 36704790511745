import { useState } from "react";
import { useDispatch } from "react-redux";
import { newCustomerAction } from "../../actions/shared";
import { motion } from "framer-motion";
import DatePicker from "react-date-picker";
import { format } from "date-fns";

import { CustomerSchema } from "../../schema/validations";
import { Formik, Form, Field } from "formik";

const NewCustomer = ({ setNewCustomer, currentPage, page, setPagination }) => {
  const dispatch = useDispatch();

  const [dte, onChange] = useState(new Date());

  const newCustomer = (value) => {
    // e.preventDefault();

    const join = format(dte, "yyyy-MM-dd hh:mm:ss");

    const payload = {
      name: value.name,
      phone: value.phone,
      email: value.email,
      street: value.street,
      city: value.city,
      state: value.state,
      zip: value.zip,
      notes: value.notes,
      join,
    };
    const info = {
      page,
      status: currentPage,
    };

    dispatch(newCustomerAction(payload, info));

    setNewCustomer(false);
    setPagination(true);
    //history.push("/overview");
  };

  return (
    <div className="form-page">
      <Formik
        initialValues={{
          name: "",
          phone: "",
          email: "",
          street: "",
          city: "",
          state: "",
          zip: "",
          notes: "",
        }}
        validationSchema={CustomerSchema}
        onSubmit={(values) => {
          newCustomer(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <h3>New Customer</h3>
            <div className="input-control">
              <Field type="text" placeholder="Name" name="name" />
              {errors.name && touched.name ? (
                <div className="form-errors">{errors.name}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field type="text" placeholder="Phone" name="phone" />
              {errors.phone && touched.phone ? (
                <div className="form-errors">{errors.phone}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="Email" type="text" name="email" />
              {errors.email && touched.email ? (
                <div className="form-errors">{errors.email}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="Street" type="text" name="street" />
              {errors.street && touched.street ? (
                <div className="form-errors">{errors.street}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="City" type="text" name="city" />
              {errors.city && touched.city ? (
                <div className="form-errors">{errors.city}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="State" type="text" name="state" />
              {errors.state && touched.state ? (
                <div className="form-errors">{errors.state}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field placeholder="Zip" type="text" name="zip" />
              {errors.zip && touched.zip ? (
                <div className="form-errors">{errors.zip}</div>
              ) : null}
            </div>
            <div className="input-control">
              <Field
                as="textarea"
                placeholder="Notes"
                name="notes"
                cols="30"
                rows="1"
              ></Field>
              {errors.notes && touched.notes ? (
                <div className="form-errors">{errors.notes}</div>
              ) : null}
            </div>
            <div>
              <DatePicker onChange={onChange} value={dte} />
            </div>
            <motion.button
              type="submit"
              whileHover={{
                scale: 0.9,
                color: "#f8e112",
              }}
              transition={{
                type: "spring",
                stiffness: 300,
              }}
              className="form-btn cursor"
            >
              Create Customer
            </motion.button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewCustomer;
