import { toast } from "react-toastify";
import { logout } from "../../actions/auth";
import Links from "./Links";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import { menuAction } from "../../actions/overview";

export const variants = {
  initial: {
    x: 0,
  },
  open: {
    // opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    // opacity: 0,
    x: 260,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Menu = ({ menu }) => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(menuAction());
    toast.dark("Guardian is logging you out, please wait...", {
      autoClose: 2000,
      onClose: () => {
        dispatch(logout());
      },
    });
  };

  //nav menu control

  const handleMenuClose = () => {
    console.log("called");
    dispatch(menuAction());
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className="left-slide-menu"
        variants={variants}
        animate={menu ? "open" : "closed"}
        initial={false}
        //onClick={() => handleMenuClose()}
      >
        <div className="menu-content">
          <Links logOut={logOut} handleMenuClose={handleMenuClose} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Menu;
