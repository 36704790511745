import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { loginCreator, error } from "../actions/auth";
import { Redirect, useHistory } from "react-router-dom";
import PageMotion from "../page-motion";

import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { AuthSchema } from "../schema/validations";

const Auth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuth, errors } = useSelector((state) => state.auth);

  useEffect(() => {
    logCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    toast.error(errors, {
      autoClose: 3000,
    });
    return () => {
      dispatch(error(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const logCheck = () => {
    if (isAuth) {
      history.push("/overview");
    }
  };

  const login = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };

    dispatch(loginCreator(payload));
  };

  return (
    <>
      {isAuth ? (
        <Redirect to="/overview" />
      ) : (
        <PageMotion>
          <div className="auth-page">
            <div className="auth-container">
              <div className="logo">
                Guardian <span>Angel</span>
              </div>
              <div className="form-page">
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={AuthSchema}
                  onSubmit={async (values) => {
                    await login(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="input-control">
                        <Field
                          type="text"
                          placeholder="Enter your email."
                          name="email"
                        />
                        {errors.email && touched.email ? (
                          <div className="form-errors">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="input-control">
                        <Field
                          // value={password}
                          type="password"
                          name="password"
                          placeholder="Enter your password."
                          // onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && touched.password ? (
                          <div className="form-errors">{errors.password}</div>
                        ) : null}
                      </div>
                      <motion.button
                        type="submit"
                        whileHover={{
                          scale: 0.9,
                          color: "#f8e112",
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 300,
                        }}
                        className="form-btn cursor"
                      >
                        Login
                      </motion.button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </PageMotion>
      )}
    </>
  );
};

export default Auth;
