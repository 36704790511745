import { useDispatch } from "react-redux";
import { updateUserAction } from "../../actions/auth";
import { Formik, Form, Field } from "formik";
import { UserSchema } from "../../schema/validations";
import { motion } from "framer-motion";

const UserUpdate = ({ setUpdate, user }) => {
  const dispatch = useDispatch();

  const updateUser = (values) => {
    const { password, name, email } = values;
    const payload = {
      password,
      name,
      email,
    };

    console.log(payload);
    dispatch(updateUserAction(payload));
    setUpdate(false);
  };
  return (
    <Formik
      initialValues={{
        name: user.name,
        email: user.email,
        password: user.password,
      }}
      validationSchema={UserSchema}
      onSubmit={(values) => {
        updateUser(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="form-page">
          <div className="input-control">
            <Field name="email" type="text" placeholder="Email" />
            {errors.email && touched.email ? (
              <div className="form-errors">{errors.name}</div>
            ) : null}
          </div>
          <div className="input-control">
            <Field name="name" type="text" placeholder="Your Name" />
            {errors.name && touched.name ? (
              <div className="form-errors">{errors.name}</div>
            ) : null}
          </div>
          <div className="input-control mb">
            <Field
              name="password"
              type="password"
              placeholder="Enter Password"
            />
            {errors.password && touched.password ? (
              <div className="form-errors">{errors.password}</div>
            ) : null}
          </div>
          <motion.button className="btns" type="submit">
            Update {user.name}
          </motion.button>
        </Form>
      )}
    </Formik>
  );
};

export default UserUpdate;
