import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Stats = ({ stats, icon, name }) => {
  return (
    <div className="stats">
      <div className="stats-data">
        <div className="stats-number">{stats}</div>
        <div className="icon">
          <FontAwesomeIcon icon={icon} size="1x" />
        </div>
      </div>
      <div className="stats-name">{name}</div>
    </div>
  );
};

export default Stats;
