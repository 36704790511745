import { motion, AnimatePresence } from "framer-motion";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    x: "-100vw",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.3,
    },
  },
};

const ModalPage = ({ closeModal, children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {/* {updateCustomer && ( */}
      <motion.div
        variants={backdrop}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="backdrop"
      >
        <motion.div
          className="modal-box"
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div className="modal-content">
            {children}

            <div className="modal-action">
              <motion.div
                whileHover={{
                  x: -10,
                  color: "#f8e112",
                }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                }}
                onClick={closeModal}
                className="btn-cancel cursor"
              >
                <FontAwesomeIcon icon={faTimes} />
                <span>Cancel</span>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>
      {/* )} */}
    </AnimatePresence>
  );
};

export default ModalPage;
