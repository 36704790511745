//Bar

export const hoverVariant = {
  hover: {
    x: -5,
    color: "#7bb6cf",
  },
};

export const btnFilter = {
  hover: {
    scale: 0.9,
    backgroundColor: "#87BCDE",
  },
  tap: {
    backgroundColor: "#637074",
  },
};
