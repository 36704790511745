import {
  LOADING,
  GET_ALL_CUSTOMERS,
  PAGINATOR,
  GET_CUSTOMER,
  TOGGLE_COMPLETE,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER_SERVICE,
  UPDATE_CUSTOMER_SERVICE,
  FIND_CUSTOMERS,
  NEW_CUSTOMER,
  NEW_SERVICE,
  CLEAR_CUSTOMER,
  SHOW_ALL,
  PAGE_COUNT,
} from "../types/types";

//customer reducer

const initState = {
  customers: [],
  customer: {},
  services: [],
  loading: false,
  errors: [],
  currentPage: 1,
  lastPage: 0,
  total: 0,
  count: 0,
  page: parseInt(localStorage.getItem("page")) || 5,
  search: false,
};

export const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case PAGE_COUNT:
      return {
        ...state,
        page: action.page.value,
      };

    case GET_ALL_CUSTOMERS:
      const { data, paginatorInfo } = action.payload;
      return {
        ...state,
        customers: data,
        currentPage: paginatorInfo.currentPage,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total,
        count: paginatorInfo.count,
        search: true,
      };
    case SHOW_ALL:
      return {
        ...state,
        customers: action.payload,
      };

    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
        services: action.customer.services,
      };
    case NEW_CUSTOMER:
      const newCustomer = state.customers.concat([action.payload]);
      return {
        ...state,
        customers: newCustomer,
        customer: action.payload,
      };

    case LOADING:
      return {
        ...state,
        loading: action.load,
      };
    case PAGINATOR:
      return {
        ...state,
      };
    case CLEAR_CUSTOMER:
      return {
        ...state,
        customer: {},
        services: [],
      };

    case UPDATE_CUSTOMER:
      // return Object.assign({}, state.customer, {
      //   name: action.payload.name,
      // });
      const { name, phone, email, street, city, zip, join } = action.payload;
      return {
        ...state,
        customer: {
          ...state.customer,
          name,
          phone,
          email,
          street,
          city,
          zip,
          join,
          //duplicate issue with user state ex. NY
          state: action.payload.state,
        },
      };

    case NEW_SERVICE:
      const newService = state.services.concat([action.payload]);
      return {
        ...state,
        services: newService,
      };

    case UPDATE_CUSTOMER_SERVICE:
      const cust_service = state.services.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }
        return Object.assign({}, item, action.payload);
      });

      return {
        ...state,
        services: cust_service,
      };

    case TOGGLE_COMPLETE:
      const { id, status } = action.payload;

      const service = state.services.map((item) => {
        if (item.id !== id) {
          return item;
        }
        return Object.assign({}, item, { complete: status });
      });

      return {
        ...state,
        services: service,
      };

    case FIND_CUSTOMERS:
      return {
        ...state,
        customers: action.customer,
      };
    case DELETE_CUSTOMER_SERVICE:
      const filterServices = state.services.filter(
        (service) => service.id !== action.id
      );

      return {
        ...state,
        services: filterServices,
      };

    default:
      return state;
  }
};
