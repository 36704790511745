//AUTH
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const IS_AUTH = "ISAUTH";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_USER = "UPDATE_USER";

//customers
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const UPDATE_CUSTOMER_SERVICE = "UPDATE_CUSTOMER_SERVICE";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const NEW_CUSTOMER = "NEW_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const FIND_CUSTOMERS = "FIND_CUSTOMERS";
export const PAGINATOR = "PAGINATOR";
export const DELETE_CUSTOMER_SERVICE = "DELETE_CUSTOMER_SERVICE";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";

export const SHOW_ALL = "SHOW_ALL";
export const TODAY = "TODAY";
export const WEEK = "WEEK";
export const MONTH = "MONTH";
export const YEAR = "YEAR";
export const PAGE_COUNT = "PAGE_COUNT";

//services

export const SERVICES = "SERVICES";
export const NEW_SERVICE = "NEW_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const JOBS = "JOBS";
export const JOBS_COMPLETE = "JOBS_COMPLETE";
export const ONE_JOB = "ONE_JOB";

//overview
export const STATS = "STATS";

//shaFIND
export const LOADING = "LOADING";
export const TOGGLE_COMPLETE = "TOGGLE_COMPLETE";
export const ERRORS = "ERRORS";
export const MENU = " MENU";
