import { useDispatch } from "react-redux";
import { updatePasswordCreator } from "../../actions/auth";
import { Formik, Form, Field } from "formik";
import { UpdatePasswordSchema } from "../../schema/validations";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
const PasswordForm = ({ setPassword }) => {
  const dispatch = useDispatch();

  const updatePassword = (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      toast.error("Passwords must match", {
        position: toast.POSITION.CENTER,
        autoClose: 5000,
      });
      return;
    }
    const payload = {
      password: oldPassword,
      newPassword,
    };

    console.log(payload);
    dispatch(updatePasswordCreator(payload));
    setPassword(false);
  };
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={UpdatePasswordSchema}
      onSubmit={(values) => {
        updatePassword(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="form-page">
          <div className="input-control">
            <Field
              name="oldPassword"
              type="password"
              placeholder="Current Password"
            />
            {errors.oldPassword && touched.oldPassword ? (
              <div className="form-errors">{errors.oldPassword}</div>
            ) : null}
          </div>
          <div className="input-control">
            <Field
              name="newPassword"
              type="password"
              placeholder="New Password"
            />
            {errors.newPassword && touched.newPassword ? (
              <div className="form-errors">{errors.newPassword}</div>
            ) : null}
          </div>
          <div className="input-control mb">
            <Field
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <div className="form-errors">{errors.confirmPassword}</div>
            ) : null}
          </div>
          <motion.button type="submit" className="btns">
            Update Password
          </motion.button>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordForm;
